<template>
  <div>
    <b-img
      v-if="logo"
      :src="logo"
      :alt="$t('invoice_logo') || 'Invoice Logo'"
      width="40"
      height="40"
    />
  </div>
</template>

<script>
import { BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  name: "Logo",
  components: {
    BImg,
  },
  data() {
    return {};
  },
  computed: {
    logo() {
      return $themeConfig?.app?.appLogoImage;
    },
  },
};
</script>
